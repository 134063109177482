require('normalize.css');
require('../../assets/css/main.scss');
require('./index.scss');

import avatar from './images/olga-lark.jpg';
document.getElementById('avatar').src = avatar;

import cv from '../../olga_lark_cv.pdf';
document.getElementById('cv').href = cv;

import jmp from '../../olga_lark_jmp.pdf';
document.getElementById('jmp').href = jmp;

import wp22_6 from '../../wp22_6.pdf';
document.getElementById('wp22_6').href = wp22_6;

import wp22_13 from '../../wp22_13.pdf';
document.getElementById('wp22_13').href = wp22_13;

import icfi from '../../Lark_ImportCompetitionAndFirmInnovation_2024Feb.pdf';
document.getElementById('icfi').href = icfi;

